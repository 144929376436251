import { useState,useEffect } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { addToList,getSub ,payement,contact} from '../api/api';
import './subscription.css'
const Subscription = () => {
    
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
    });

    const [payData, setPayData] = useState({
        name: '',
        email: '',
        phone: '',
        city: '',
        country:'',
        amount: null
    });

    const [openModal, setOpenModal] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [om2, setOm2] = useState(false);
    const [priceTLoaded, setPriceTLoaded] = useState(false);
    const [price_type, setPrice_type] = useState(' ');
    const[contactData,setContactData] = useState ({
        email:'',
        subject:'',
        message:''
    });
    const [contactForm,setContactForm] = useState(false)

    // close modal
    const cancelModal = () => {
        setOpenModal(false);
        setOm2(false)

    }
    //fetch data subsc
    useEffect(() => {
        const fetchSubscriptionData = async () => {
            try {
                const result = await getSub();
                console.log(result)
                setSubscriptionData(result.subscriptions[0]);


            } catch (error) {
                console.error('Error fetching subscription data:', error);
            }
        };
        fetchSubscriptionData();
    }, []);

    //set amount for sub as well as upgrading priceTloaded
    useEffect(() => {
        if (subscriptionData) {
            setPrice_type(subscriptionData.price);
            setPayData(prevPayData => ({
                ...prevPayData,
                amount: subscriptionData.price,
            }));
            setPriceTLoaded(true);        }
    }, [subscriptionData]);
    
    // call add To list API
    const addToListfn = async (data) => {
        try {
            const result = await addToList(data);
            if (result.success)
             setOpenModal(true);


        } catch (error) {
            console.error('Error :', error);
        }

    }

    //addtoListAfterPay
    const addToListfnAfterPay = async (data) => {
        try {
            const result = await addToList(data);

        } catch (error) {
            console.error('Error :', error);
        }

    }
   
   
    // handle Inputs change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    //handle payement change
    const handlePayChange = (e) => {
        const { name, value } = e.target;
        setPayData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

//handle Contact Change
    const handleContactChange = (e) => {
        const {name,value} = e.target;
        setContactData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    //handle pay submit
    const handlePaySubmit = (e) => {
        e.preventDefault();
        payement(payData).then((responseData) => {
            window.location.href = responseData.redirect_url;

        });
        addToListfnAfterPay(formData);


    }

//handleContact Submit
    const handleContactSubmit = (e) => {
        e.preventDefault();
        contact(contactData).then((responseData)=> {
            window.alert('استلمنا رسالتك بنجاح,سنرد عليك في اقرب الاوقات')
        })
    }

    //submit form
    const handleSubmit = (e) => {
        e.preventDefault();
        if (priceTLoaded) {
        if (price_type === null) {
            // If payment is not done yet
            addToListfn(formData); 
        } else {
            setOm2(true);
             }
        }
        //console.log('Form Data:', formData);
    };
    
    console.log('thepriceTloaded,', priceTLoaded);
    console.log('price_type is', price_type);

    

    return (
        <>

              <Modal show={om2} onHide={cancelModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form onSubmit={handlePaySubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" value={payData.name} onChange={handlePayChange} />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name="email" value={payData.email} onChange={handlePayChange} />
                    </Form.Group>
                    <Form.Group controlId="formPhone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="tel" name="phone" value={payData.phone} onChange={handlePayChange} />
                    </Form.Group>
                    <Form.Group controlId="formCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" name="city" value={payData.city} onChange={handlePayChange} />
                    </Form.Group>
                    <Form.Group controlId="formCountry">
                        <Form.Label>Country</Form.Label>
                        <Form.Control type="text" name="country" value={payData.country} onChange={handlePayChange} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={cancelModal}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                 
                <Modal show={openModal} onHide={cancelModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>تهانينا Nice</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Your name has been added successfully added to the list please check your email for more inforamtion about the giveaway</p>
                        <p>تم اضافة اسمك بنجاح الرجاء مراجعة ايميلك لمزيد التفاصل حول اللعبة و السحب</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={cancelModal}>
                            Cancel
                        </Button>

                    </Modal.Footer>
                </Modal>     
                 
                 <Modal show={contactForm} onHide={cancelModal} size="lg" centered>
                     <Modal.Header style={{background: "#E7490C",textAlign:"center", display: "flex", justifyContent:"center"}}>
                     <Modal.Title className='text-white' style={{fontFamily:"Cairo", textDecoration:"underline"}} >طلب إدارة الموقع </Modal.Title>
                     </Modal.Header>

                     <Modal.Body style={{ direction: "rtl" }}>
                        <p className='text-black p-2' style={{fontWeight:"500", border:"solid 2px black"}}>إذا كنت شخصًا مشهورًا أو تدير لايف، وترغب في تنظيم مسابقة على موقعنا، يُرجى التواصل مع إدارة الموقع عبر النموذج أدناه.</p>
                        <p className='text-black' style={{fontWeight:"500"}}>ستتمكن من:</p>

                         <div style={{ fontSize: "1.5em", color: "green", marginBottom: "20px" }}>
                        <i className="bi bi-check-square-fill" >  -ادارة صفحة الاشتراكات و تحديد سعر الاشتراك-</i>
                        </div>
                        <div style={{ fontSize: "1.5em", color: "green", marginBottom: "20px" }}>
                        <i className="bi bi-check-square-fill"  > - اجراء السحب عن طريق عجلة الحظ سواء بالاقصاء أو على مرة واحدة- </i>
                        </div>
                        <div style={{ fontSize: "1.5em", color: "green", marginBottom: "20px" }}>
                        <i className="bi bi-check-square-fill"  > - قبول أسماء المشتركين و تحويلهم الى العجلة تلقائيا  - </i>
                        </div>
                        <p  className='text-black' style={{fontWeight:"500"}}>سيتم تحويل اموال الاشتراكات الى حسابك البنكي عند انتهاء اللعبة بواسطة الإدارة.</p>
                        <p  className='text-black' style={{fontWeight:"500",fontFamily:"Cairo", textDecoration:"underline" }}>عند القبول سيصلكم  مدير الموقع بايميل به معلومات الدخول.</p>


                    <Form onSubmit={handleContactSubmit}>
                    <Form.Group controlId="formEmailContact">
                        <Form.Label>البريد الالكتروني</Form.Label>
                        <Form.Control type="email" name="email" value={contactData.email} onChange={handleContactChange} />
                    </Form.Group>
                    <Form.Group controlId="formSubjectContact">
                        <Form.Label>الموضوع</Form.Label>
                        <Form.Control type="text" name="subject" value={contactData.subject} onChange={handleContactChange} placeholder='طلب ادارة الموقع ' />
                    </Form.Group>
                    <Form.Group controlId="formMessage">
                        <Form.Label>الرسالة</Form.Label>
                        <Form.Control as="textarea" rows={3} name="message" value={contactData.message} onChange={handleContactChange} placeholder='اريد ادارة لعبة عجلة الحظ خلال المدة ... '/>
                    </Form.Group>
                    <Button className='m-3' variant="primary" type="submit">
                        إرسال
                    </Button>
                    <Button className='m-3' variant="danger" onClick={()=> {setContactForm(false)}}>
                        إلغاء
                    </Button>
                    </Form>


                    </Modal.Body>
                    </Modal> 
             
                 <header className="site-header">
                    <div className="container">
                <div className="row">

                    <div className="col-lg-6 col-12 d-flex flex-wrap">
                        <p className="d-flex me-4 mb-0">
                            <i className="bi-person custom-icon me-2"></i>
                            <strong className="text-dark">Welcome to BetSpinWheel</strong>
                        </p>
                    </div>
                    <div className="col-lg-6 col-12 d-flex flex-wrap  justify-content-end">
                        <p className="d-flex me-4 mb-0">
                            <strong className="text-dark">إشترك معنا للفوز بجوائز قيمة</strong>
                        </p>
                    </div>

                </div>
            </div>
                  </header>


                   <nav class="navbar navbar-expand-lg">
            <div class="container">
                <a class="navbar-brand" href="https://betspinwheel.com/subscription">
                    BetSpinWheel
                </a>


                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class=" ul navbar-nav align-items-lg-center ms-auto me-lg-5">
                        <li class="nav-item">
                            <a class="nav-link click-scroll" style={{fontWeight:"600"}} href="#section_1">الرئيسية</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link click-scroll" style={{fontWeight:"600"}} href="#section_2">المسابقة</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link click-scroll" style={{fontWeight:"600"}} href="#section_3">صور</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link click-scroll" style={{fontWeight:"600"}} href="#section_4">الاشتراك</a>
                        </li>
                         <li class="nav-item">
                            <a class="nav-link click-scroll"  style={{fontWeight:"600", cursor:"pointer"}} onClick={()=> setContactForm(true)}>طلب ادارة</a>
                        </li>
                    </ul>

                    <a href="https://betspinwheel.com/" class="btn custom-btn d-lg-block d-none">دخول المدير</a>
                </div>
            </div>
                   </nav>

                   <section class="hero-section" id="section_1">
                     <div class="section-overlay"></div>

            <div class="container d-flex justify-content-center align-items-center">
                <div class="row">

                    <div class="col-12 mt-auto mb-5 text-center">
                        <small style={{fontSize: "18px", color: "#F8CB2E"}}> تقدم المسابقة التالية <span style={{fontSize: "15px", fontWeight:"700", fontFamily:"Cairo"}}>BetSpinWheel </span></small>
                        {subscriptionData && (priceTLoaded === true) && (
                        <h1 class="text-white mb-5" style={{fontSize: "100px", fontWeight:"700", fontFamily:"Cairo"}}>{subscriptionData.title}</h1> ) }

                        <a class="btn custom-btn smoothscroll" href="#section_4">الاشتراك الآن</a>
                    </div>

                    <div class="col-lg-12 col-12 mt-auto d-flex flex-column flex-lg-row text-center">
                        <div class="date-wrap">
                                <i class="custom-icon bi-clock me-2"></i>
                                {subscriptionData && (priceTLoaded === true) && (
                        <span class="text-white" style={{ fontFamily:"Cairo", fontSize:"1.25rem", fontWeight:"600"}}>{subscriptionData.time}</span> ) }
                        </div>

                        <div class="location-wrap mx-auto py-3 py-lg-0">
                            <h5 class="text-white">
                            <i class="custom-icon bi bi-coin me-2"></i>
                                {subscriptionData && (priceTLoaded === true) && ( 
                                    <>
                                {(subscriptionData.price) !== null ? (
                                   <span className='price'>Price of subscription : {subscriptionData.price} AED</span>) : (
                                         <span className='price' style={{color: "greenyellow"}}>إشتراك مجاني</span>
                                     )} 
                                     </> )}
                            </h5>
                        </div>

                        <div class="social-share">
                            <ul class="social-icon d-flex align-items-center justify-content-center ul">
                                <span class="text-white me-3">Share:</span>

                                <li class="social-icon-item">
                                    <a href="https://www.facebook.com/profile.php?id=61558418519652&mibextid=LQQJ4d" class="social-icon-link">
                                        <span class="bi-facebook"></span>
                                    </a>
                                </li>

                                <li class="social-icon-item">
                                    <a href="https://twitter.com/ubet9875388969?s=21" class="social-icon-link">
                                        <span class="bi-twitter"></span>
                                    </a>
                                </li>

                                <li class="social-icon-item">
                                    <a href="https://www.instagram.com/u_b.e.t?igsh=MXhrNGl6bDNtNXl2aA%3D%3D&utm_source=qr" class="social-icon-link">
                                        <span class="bi-instagram"></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="video-wrap">
                 <img src="https://i.kinja-img.com/image/upload/c_fill,h_900,q_60,w_1600/ntdbfraz79plgssbg9qz.jpg" className='custom-video'/>
            </div> 
                   </section>

                   <section className="about-section section-padding pb-2" id="section_2">
                       <div className="container">
                <div className="row">

                         <div class="col-lg-6 col-12">
                        <div class="about-text-wrap">
                        {subscriptionData && (priceTLoaded === true) && (
                            <>
                                {subscriptionData.imageUrl.length > 0 && (
                                    <img src={`https://www.api.betspinwheel.com/uploads/${subscriptionData.imageUrl[0]}`} alt={`Subscription 0`} className='artists-image img-fluid' style={{ border: "2px solid orange" }} />
                                )}
                           </> )}
                        </div>
                    </div>

                      <div className="col-lg-6 col-12 mb-4 mb-lg-0 d-flex align-items-center justify-content-end">
                        <div className="services-info text-end">
                            <h2 className="text-white mb-4">حول المسابقة</h2>
                            {subscriptionData && (priceTLoaded === true) && (
                       <p className='p text-white' style={{fontFamily:"Cairo"}} dangerouslySetInnerHTML={{ __html: subscriptionData.description.replace(/\n/g, '<br />') }}></p>) }
                        </div>
                    </div>
                      
                </div>
                <div className='row'>
                <div class="col-lg-12 col-12 d-flex  align-items-center justify-content-end">
                    <h4 className='p-2 text-white font-weight-bold' style={{fontFamily:"Cairo"}}>يرجى العلم أنه سيتم اعلامكم في حالة القبول من المدير و كذلك في حالة الربح أو الخسارة بإيميل</h4>
                    <i class="bi bi-exclamation-triangle about-text-icon"></i>

                </div>
                </div>
                <div className='row pt-2'>
                <div class="col-lg-12 col-12 d-flex  align-items-center justify-content-end">
                    <h4 className='p-2 text-white font-weight-bold' style={{fontFamily:"Cairo"}}>اسم المشترك يجب أن يكون مميزا حتى لا يقع أي تشابه مع اسماء أخرى.لا نقبل أسماء مخلة للأخلاق</h4>
                    <i class="bi bi-exclamation-triangle about-text-icon"></i>

                </div>
                </div>
                <div className='row pt-2'>
                <div class="col-lg-12 col-12 d-flex  align-items-center justify-content-end">
                    <h4 className='p-2 text-white font-weight-bold' style={{fontFamily:"Cairo"}}>عندما يقبل المدير إسمكم سيصلكم فورا ايميل به رابط الايف في احدى قنواتنا على اليوتيوب أو التكتوك </h4>
                    <i class="bi bi-exclamation-triangle about-text-icon"></i>

                </div>
                </div>   
            </div>
                   </section>

                   <section class="artists-section p-5" id="section_3" style={{background:"white"}}>
             <div class="container">
                <div class="row justify-content-center">

                    <div class="col-12 text-center">
                        <h1 class="mb-4 text-black font-wheight-bold ">الصور</h1>
                    </div>

                    <div class="col-lg-5 col-12">
                        <div class="artists-thumb">
                            <div class="artists-image-wrap">
                            {subscriptionData && (priceTLoaded === true) && (
                                <>
                                {subscriptionData.imageUrl.length > 0 && (
                                    <img src={`https://www.api.betspinwheel.com/uploads/${subscriptionData.imageUrl[0]}`} alt={`Subscription 0`} className='artists-image img-fluid' style={{ border: "2px solid orange" }} />
                                )}
                                </>
                            )}
                            </div>
                        </div>
                    </div>

                    

                    <div class="col-lg-5 col-12">
                        <div class="artists-thumb">
                            <div class="artists-image-wrap">
                            {subscriptionData && (priceTLoaded === true) && (
                                <>
                                {subscriptionData.imageUrl.length > 1 && (
                                    <img src={`https://www.api.betspinwheel.com/uploads/${subscriptionData.imageUrl[1]}`} alt={`Subscription 1`} className='artists-image img-fluid' style={{ border: "2px solid orange" }} />
                                )}
                                </>
                            )}
                            </div>

                        </div>
                        </div>

                        
                    <div className='row justify-content-center'>
                    <div class="col-lg-5 col-12">
                        <div class="artists-thumb">
                        {subscriptionData && (priceTLoaded === true) && (
                                <>
                                {subscriptionData.imageUrl.length > 2 && (
                                    <img src={`https://www.api.betspinwheel.com/uploads/${subscriptionData.imageUrl[2]}`} alt={`Subscription 2`} className='artists-image img-fluid' style={{ border: "2px solid orange" }} />
                                )}
                                </>
                            )}
                        </div>
                        </div>
                        <div class="col-lg-5 col-12">
                        <div class="artists-thumb">
                        {subscriptionData && (priceTLoaded === true) && (
                                <>
                                {subscriptionData.imageUrl.length > 3 && (
                                    <img src={`https://www.api.betspinwheel.com/uploads/${subscriptionData.imageUrl[3]}`} alt={`Subscription 3`} className='artists-image img-fluid' style={{ border: "2px solid orange" }} />
                                )}
                                </>
                            )}
                        </div>
                        </div>
                    </div>

                    </div>

            </div>
                   </section> 

                    <section class="contact-section section-padding section-bg" id="section_4">
            <div class="container">
                <div class="row">

                    <div class="col-lg-8 col-12 mx-auto">
                        <h2 class="text-center mb-4">جاهز للاشتراك؟ فقط الكنية الخاصة بك مع ايميلك</h2>

                        <div class="tabb-contentt shadow-lg mt-5" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-ContactForm" role="tabpanel"
                                aria-labelledby="nav-ContactForm-tab">
                                <div class="custom-form contact-form mb-5 mb-lg-0">
                                    <div class="contact-form-body">
                                        <div class="row">
                                       <Form onSubmit={handleSubmit}>
                                        <Form.Group className="form-goupe">
                                       <Form.Control type="text"
                                        name="fullName"
                                        value={formData.fullName}
                                        onChange={handleInputChange}
                                        placeholder="يرجى إدخال الكنية الخاصة بك" 
                                        required/>
                                </Form.Group>
                                <Form.Group className="form-goupe">
                                    <Form.Control type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder="يرجى إدخال بريدك الإلكتروني"
                                        required />
                                </Form.Group>
                                           <div class="col-lg-4 col-md-10 col-8 mx-auto">
                                            <button type="submit" class="form-control">الاشتراك</button>
                                            </div>
                                            { subscriptionData && (priceTLoaded === true) && ( 
                                                <>
                                             {(subscriptionData.price) !== null ? (
                                              <p>Payement Secured with Paytabs</p> ) 
                                              : null
                                               } 
                                               </>
                                            )}
                                  </Form>
                                        </div>


                                        
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>

                </div>
            </div>
                     </section>
                    
                        <footer class="site-footer">
        <div class="site-footer-top">
            <div class="container">
                <div class="row">

                    <div class="col-lg-6 col-12">
                        <h2 class="text-white mb-lg-0">BetSpinWheel</h2>
                    </div>

                    <div class="col-lg-6 col-12 d-flex justify-content-lg-end align-items-center">
                        <ul class="social-icon d-flex justify-content-lg-end ul">
                            <li class="social-icon-item">
                                <a href="https://twitter.com/ubet9875388969?s=21" class="social-icon-link">
                                    <span class="bi-twitter"></span>
                                </a>
                            </li>


                            <li class="social-icon-item">
                                <a href="https://www.instagram.com/u_b.e.t?igsh=MXhrNGl6bDNtNXl2aA%3D%3D&utm_source=qr" class="social-icon-link">
                                    <span class="bi-instagram"></span>
                                </a>
                            </li>

                            <li class="social-icon-item">
                                <a href="https://www.youtube.com/channel/UC2JSM5qDN5AgWEgOIuPKMUA" class="social-icon-link">
                                    <span class="bi-youtube"></span>
                                </a>
                            </li>

                          
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">

                <div class="col-lg-6 col-12 mb-4 pb-2">
                    <h5 class="site-footer-title mb-3">Links</h5>

                    <ul class="site-footer-links ul">
                        <li class="site-footer-link-item">
                            <a href="https://ubet.ae/" class="site-footer-link">Ubet</a>
                        </li>

                        <li class="site-footer-link-item">
                            <a href="https://autobet.ae/" class="site-footer-link">AutoBet</a>
                        </li>

                        <li class="site-footer-link-item">
                            <a href="https://3qark.net/" class="site-footer-link">3qark</a>
                        </li>

                        <li class="site-footer-link-item">
                            <a href="https://lotsbet.net/" class="site-footer-link">Lotsbet</a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                    <h5 class="site-footer-title mb-3">تريد الاتصال بنا؟</h5>
                    <p class="text-white d-flex">
                        <a href="mailto:hello@company.com" class="site-footer-link">
                            hello@company.com
                        </a>
                    </p>
                </div>

                
            </div>
        </div>

        <div class="site-footer-bottom">
            <div class="container">
                <div class="row">

                    <div class="col-lg-3 col-12 mt-5">
                        <p class="copyright-text">Copyright © 2024 BetSpinWheel</p>
                        <p class="copyright-text">Official Company <a href="https://ubet.ae/">BoulevardEtrading</a></p>
                    </div>

                    <div class="col-lg-8 col-12 mt-lg-5">
                        <ul class="site-footer-links ul">
                            <li class="site-footer-link-item">
                                <a href="https://ubet.ae/%d8%b3%d9%8a%d8%a7%d8%b3%d8%a9-%d8%a7%d9%84%d8%a3%d8%b1%d8%ac%d8%a7%d8%b9/" class="site-footer-link">سياسة الخصوصية</a>
                            </li>

                            <li class="site-footer-link-item">
                                <a href="https://ubet.ae/privacy-and-policy/" class="site-footer-link">الشروط</a>
                            </li>

                            <li class="site-footer-link-item">
                                <a href="https://ubet.ae/privacy-and-policy/" class="site-footer-link">اخلاء المسؤولية</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
                        </footer>
        

        </>
    );
}
export default Subscription;
